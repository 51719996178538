<template>
  <div class="container col-start-center">
    <headeBar left-arrow title="燃脂塑形小班课·战队榜" :placeholder="false" @click-left="newAppBack">

    </headeBar>

    <!-- <div @click.stop="tipShow = true" :class="['rule', 'f24', 'row-center-center', appTypeStr === 'mini' ? 'mini' : '']">
      规则</div> -->
    <p :class="['tip', 'f22', appTypeStr === 'mini' ? 'mini' : '']"></p>

    <div :class="['top3-box', rankingList.length === 1 ? 'row-center-end' : 'row-start-end']">
      <div @click="goTeamDetail(rankingList[1].id)" class="top-item top-2 col-between-center">
        <div class="avator">
          <img
            :src="rankingList[1] ? rankingList[1].teamLogo : 'https://img.chaolu.com.cn/ACT/mini-team/empty_big%402x.png'"
            alt="">
        </div>
        <p v-if="rankingList[1]" class="name f26 fw6 ellipsis">{{ rankingList[1].teamName }}</p>
        <p v-else class="fake-name f26">暂无上榜</p>
        <p v-if="rankingList[1]" class="kcal f22">{{ rankingList[1].calorieCurrentMonth }}kcal</p>
        <div v-if="rankingList[1]" class="avators flex">
          <div class="aa" v-for="a,index in rankingList[1].userImgList" :key="index" :style="`background-image: url(${a})`"></div>
        </div>
        <div v-if="rankingList[1]" class="counts f22">{{ rankingList[1].teamMembers }}个成员</div>
        <img src="https://img.chaolu.com.cn/ACT/mini-team/two%402x.png" alt="" class="bb">
      </div>
      <div @click="goTeamDetail(rankingList[0].id)" class="top-item top-1 col-between-center">
        <div class="avator big">
          <img
            :src="rankingList[0] ? rankingList[0].teamLogo : 'https://img.chaolu.com.cn/ACT/mini-team/empty_big%402x.png'"
            alt="">
        </div>
        <p v-if="rankingList[0]" class="name f26 fw6 ellipsis">{{ rankingList[0].teamName }}</p>
        <p v-else class="fake-name f26">暂无上榜</p>
        <p v-if="rankingList[0]" class="kcal f22">{{ rankingList[0].calorieCurrentMonth }}kcal</p>
        <div v-if="rankingList[0]" class="avators flex">
          <div class="aa" v-for="a,index in rankingList[0].userImgList" :key="index" :style="`background-image: url(${a})`"></div>
        </div>
        <div v-if="rankingList[0]" class="counts f22">{{ rankingList[0].teamMembers }}个成员</div>
        <img src="https://img.chaolu.com.cn/ACT/mini-team/one%402x.png" alt="" class="bb">
        <img src="https://img.chaolu.com.cn/ACT/mini-team/champion%402x.png" alt="" class="cc">
      </div>
      <div @click="goTeamDetail(rankingList[2].id)" class="top-item top-3 col-between-center">
        <div class="avator">
          <img
            :src="rankingList[2] ? rankingList[2].teamLogo : 'https://img.chaolu.com.cn/ACT/mini-team/empty_big%402x.png'"
            alt="">
        </div>
        <p v-if="rankingList[2]" class="name f26 fw6 ellipsis">{{ rankingList[2].teamName }}</p>
        <p v-else class="fake-name f26">暂无上榜</p>
        <p v-if="rankingList[2]" class="kcal f22">{{ rankingList[2].calorieCurrentMonth }}kcal</p>
        <div v-if="rankingList[2]" class="avators flex">
          <div class="aa" v-for="a,index in rankingList[2].userImgList" :key="index" :style="`background-image: url(${a})`"></div>
        </div>
        <div v-if="rankingList[2]" class="counts f22">{{ rankingList[2].teamMembers }}个成员</div>
        <img src="https://img.chaolu.com.cn/ACT/mini-team/three%402x.png" alt="" class="bb">
      </div>
    </div>
    <div class="other-box">
      <template v-if="rankingList[3]">
        <div @click="goTeamDetail(item.id)" v-for="item, index in rankingList.slice(3)" :key="item.id"
          class="other-item row-between-center">
          <p class="no f34 fw6">{{ index + 4 }}</p>
          <div class="row-start-center">
            <div class="avator">
              <img :src="item.teamLogo" alt="">
            </div>
            <div class="group">
              <p class="name ellipsis f30 fw6">{{ item.teamName }}</p>
              <div class="flex">
                <div class="avators flex">
                  <div class="aa" v-for="a in item.userImgList" :key="a" :style="`background-image: url(${a})`"></div>
                </div>
                <div class="counts f22">{{ item.teamMembers }}个成员</div>
              </div>
            </div>
          </div>
          <div style="text-align: right;">
            <p class="num f34 fw6">{{ item.calorieCurrentMonth }}</p>
            <p class="unit f22">kcal</p>
          </div>
        </div>
      </template>
      <div v-else class="empty col-center-center">
        <img src="https://img.chaolu.com.cn/ACT/mini-team/my-team/empty.png" alt="">
        <p>暂无更多上榜的战队<br>快去约课给教练贡献卡路里来上榜吧！</p>
      </div>
    </div>

    <div :class="['my-buttons', buttonHide ? 'hide' : '']">
      <img @click="goAppointment" src="https://img.chaolu.com.cn/ACT/mini-team/button_course.png" alt="">
      <img @click="goMyTeam" src="https://img.chaolu.com.cn/ACT/mini-team/button_myteam.png" alt="">
    </div>


    <van-popup v-model="tipShow" position="bottom" safe-area-inset-bottom class="popsure">
      <div class="pop-rule-title">
        活动规则
        <van-icon name="cross" class="pop-rule-close" color="#fff" @click="tipShow = false" />
      </div>
      <div class="pop-rule-content" v-html="`活动规则：
1、活动时间：以自然月为一轮活动周期进行榜单数据统计。
2、战队说明：教练开课燃脂塑型小班课且有会员上课，则战队成立。
3、会员加入战队方式：完成该教练的任意一节燃脂塑形小班课，默认加入该战队。
4、战队达人：用户完成课程加入战队后，根据上课数据，累计数据前10，即可上榜【打卡达人/卡路里达人】，累计数据前5，即可上榜【拉新达人】。
5、达人数据相同排名规则：
①若用户打卡/卡路里消耗数量相同，则按用户最后一节课的时间排序，若为同一节课则按预约先后顺序排名
②若用户邀新数量相同，则按用户发起邀请时间先后排序
6、战队打卡/卡路里消耗冠军：所在战队该自然月内打卡次数/卡路里消耗队内排名第一即为【战队打卡/卡路里冠军】

榜单规则：
1、战队排名以用户总消耗卡路里
2、打卡达人榜单：在当月在该战队完成小班课次数，以用户实际上课时间为准（次月小班课作为次月统计）
3、卡路里达人榜单：在当月在该战队心率带消耗的卡路里总和（单次小班课总佩戴心率带的时长的时间上限为90分钟，超过90分钟当次小班课卡路里将判定无效，不计入战队总卡路里）。
4、邀新达人榜单：用户已加入战队（在战队有历史订单）才可获得拉新权限，且邀请的新用户需要在同一个教练战队完成一节小班课，才可计入拉新人数
  `"></div>
    </van-popup>

  </div>
</template>

<script>
import wx from "weixin-js-sdk"
import userMixin from '@/mixin/userMixin'
import headerMixin from '@/mixin/headerMixin'
import { newAppBack, initBack, gotoAppPage } from '@/lib/appMethod'
import { throttle } from '@/lib/utils'

import headeBar from '@/components/app/headBar'
export default {
  mixins: [userMixin, headerMixin],
  components: {
    headeBar
  },
  data() {
    return {
      tipShow: false,

      rankingList: [],

      timer: null,
      buttonHide: false
    }
  },
  computed: {},
  mounted() {
    window.scrollTo(0, 0)
    this.bindHeaderScroll(window, 200, (scrollTop, morehalf) => {});

    window.addEventListener('scroll', throttle(this.onScroll))
  },
  async created() {
    await this.$getAllInfo()
    initBack()

    this.getRanking()
  },
  methods: {
    newAppBack,
    onScroll() {
      this.buttonHide = true
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.buttonHide = false
      }, 500);
    },
    getRanking() {
      this.$axios.post(`${this.baseURLApp}/microTeacherTeamUser/teamRankList`, { token: this.token }).then(res => {
        this.rankingList = res.data
        this.rankingList.map((item) => {
          item.userImgList = item.userImgList.reverse()
        })
      })

    },
    goAppointment() {
      gotoAppPage(8)
    },
    goMyTeam() {
      if (this.appTypeStr === 'mini') {
        wx.miniProgram.navigateTo({
          url: `/pages/webView/index?webUrl=${window.location.origin}/#/mini-team/my-team&userId=1`
        })
      } else {
        this.$router.push(`/mini-team/my-team`)
      }
    },
    goTeamDetail(id) {
      if (!id) return
      if (this.appTypeStr === 'mini') {
        wx.miniProgram.navigateTo({
          url: `/pages/webView/index?webUrl=${window.location.origin}/#/mini-team/team-detail&teamId=${id}&userId=1`
        })
      } else {
        this.$router.push(`/mini-team/team-detail?teamId=${id}`)
      }
    },
  },
}
</script>
<style lang="less" scoped>
.share-icon {
  width: 38px;
}
.container {
  background-color: #4ABB72;
  background-image: url(https://img.chaolu.com.cn/ACT/mini-team/bg_green%402x.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  color: #fff;
  // height: 100vh;
  // overflow: auto;
  // -webkit-overflow-scrolling: touch;

  * {
    box-sizing: border-box;
  }

  .rule {
    position: fixed;
    right: 0;
    top: 250px;
    width: 88px;
    height: 56px;
    padding: 0 0 0 12px;
    background: rgba(0, 0, 0, .2);
    border-radius: 28px 0px 0px 28px;
    color: #fff;

    &.mini {
      top: 80px;
    }
  }

  .award-box {
    padding: 64px 33px 0 33px;
    width: 693px;
    height: 270px;
    background-image: url(https://img.chaolu.com.cn/ACT/mini-team/bg_award%402x.png);
    background-size: 100% 100%;
    position: relative;

    .title {
      height: 57px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -15px;
    }

    .awards {
      overflow: auto;
      -webkit-overflow-scrolling: touch;

      .p1 {
        color: #C3F5C5;
        writing-mode: vertical-lr;
        line-height: 1;
        white-space: nowrap;
        span {
          writing-mode: initial;
        }
      }

      .arrow-icon {
        width: 23px;
        margin: 0 8px 0 9px;
      }

      .award-item {
        width: 168px;
        margin: 0 30px 0 0;
      }
    }
  }

  .tip {
    margin: calc(130px) 0 36px;

    &.mini {
      margin: 0 0 36px;
    }
  }

  .top3-box {
    width: 100%;
    padding: 154px 40px 0;

    .top-item {
      position: relative;

      &.top-2,
      &.top-3 {
        width: 220px;
        height: 260px;
        background: linear-gradient(0deg, rgba(202, 248, 129, .3) 0%, rgba(160, 233, 154, .3) 100%);
        padding: 44px 0 12px;

        .avator {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: -76px;
        }
      }

      &.top-2 {
        border-radius: 30px 0px 0px 0px;
      }

      &.top-3 {
        border-radius: 0px 30px 0px 0px;
      }

      &.top-1 {
        width: 230px;
        height: 330px;
        border-radius: 30px 30px 0px 0px;
        background: linear-gradient(0deg, rgba(202, 248, 129, .7) 0%, rgba(160, 233, 154, .7) 100%);
        padding: 44px 0 0;

        .avator {
          top: -100px;
        }
      }

      .avator {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      .kcal {
        color: #FEFEFE;
      }

      .name {
        width: 90%;
        text-align: center;
      }

      .fake-name {
        width: 90%;
        text-align: center;
        margin: 48px 0 0;
      }

      .bb {
        height: 35px;
        margin: 16px 0 0;
      }

      .cc {
        height: 72px;
      }
    }

  }

  .other-box {
    width: 100%;
    min-height: 60vh;
    padding: 36px 32px 80px;
    background: #F5F5F5;
    border-radius: 30px 30px 0px 0px;
    color: #1E212E;

    .other-item {
      padding: 24px 32px;
      background-color: #fff;
      margin: 0 0 12px;
      border-radius: 12px;

      .no {
        color: #7C849B;
      }

      .group {
        width: 330px;
        margin: 0 0 0 20px;
      }

      .name {
        margin: 0 0 12px;
      }

      .counts {
        margin: 0 0 0 10px;
      }

      .num {
        margin: 0 0 18px;
      }
    }
  }

  .avator {
    width: 108px;
    height: 108px;
    background-image: url(https://img.chaolu.com.cn/ACT/mini-team/border_middle%402x.png);
    background-size: 100% 100%;
    padding: 11px 10px 10px;

    &.big {
      width: 132px;
      height: 132px;
      padding: 13px 12px 12px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: #fff;
    }
  }

  .avators {
    flex-direction: row-reverse;

    .aa {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      border: 2px solid #fff;
      background-size: cover;
      position: relative;
      margin: 0 0 0 -8px;
    }
  }

  .my-buttons {
    width: 80px;
    position: fixed;
    right: 32px;
    bottom: 140px;
    transition: all .4s;
    &.hide {
      right: -52px;
    }
    img {
      width: 100%;
      display: block;
      margin: 24px 0 0;
    }
  }

  .empty {
    margin: 80px 0 0;

    img {
      width: 320px;
      height: 320px;
    }

    p {
      margin-top: 21px;
      text-align: center;
      line-height: 44px;
      color: #6C727A;
      font-size: 28px;
    }
  }

  .popsure {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    color: #242831;
    .pop-rule-title {
      height: 132px;
      font-weight: bold;
      background: linear-gradient(180deg, #a2d5bc 0%, #FFFDFC 100%);
      font-size: 32px;
      text-align: center;
      line-height: 130px;
      position: relative;

      .pop-rule-close {
        position: absolute;
        right: 32px;
        top: 38px;
        width: 44px;
        height: 44px;
        line-height: 44px;
        border-radius: 50%;
        background: rgba(39, 40, 49, 0.2);
        font-size: 28px;
      }
    }

    .pop-rule-content {
      font-size: 26px;
      padding: 0 52px;
      max-height: 900px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      padding-bottom: 60px;
      white-space: pre-line;
      .l-title {
        text-align: center;
        color: #195B39;
        margin: 0 0 24px;
      }
      img {
        width: 100%;
        margin: 0 0 48px;
      }
    }
  }

  .confirm-box {
    width: 590px;
    background-color: #fff;
    padding: 50px 56px 20px;
    border-radius: 24px;
    color: #242831;

    .tt {
      height: 57px;
      margin: 0 0 24px;
    }

    .title {
      margin: 0 0 32px;
    }

    .award-list {
      width: 100%;

      .award-item {
        width: 220px;
      }
    }
  }

  .result-close {
    width: 60px;
    height: 60px;
    margin: 48px auto 0;
    display: block;
  }
}</style>
