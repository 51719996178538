<template>
  <div class="switch-button f24 flex">

    <div @click="dataTabClick(index)" :class="['switch-item', 'row-center-center', tabActive === index ? 'active' : '']" v-for="item,index in dataTabs" :key="item.name">{{ item.name }} <van-icon class="arrow" v-if="index === 1" size="9" name="play" /></div>


    <van-popup v-model="dateShow" position="bottom" class="popsure">
      <van-datetime-picker
      v-model="currentDate"
      type="year-month"
      title="选择年月"
      :min-date="minDate"
      :max-date="maxDate"

      :formatter="formatter"
      @cancel="dateShow = false"
      @confirm="dateChange"
    />
    </van-popup>

  </div>
</template>
<script>
import { getFormatDate } from '@/lib/utils'

export default {
  data() {
    return {
      tabActive: 1,
      dataTabs: [{name: '历史'}, {name: '本月'}],

      minDate: new Date(2023, 5, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      dateShow: false,

      firstClick: false,
    }
  },
  methods: {
    dataTabClick(v) {
      this.tabActive = v

      // 由历史切换到本月  不唤起日期弹窗
      if (v === 1 && !this.firstClick) {
        this.dateShow = true
      } else {
        v === 0 ? this.$emit('dateChange', '') : this.$emit('dateChange', getFormatDate(this.currentDate, 'yyyy-MM'))
      }
      this.firstClick = v === 0 ? true : false
    },


    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      }
      return val;
    },
    dateChange(v) {
      this.dateShow = false

      const chooseItem = getFormatDate(v, 'yyyy-MM'), nowItem = getFormatDate(new Date(), 'yyyy-MM')

      this.dataTabs[1].name = chooseItem === nowItem ? '本月' : chooseItem

      this.$emit('dateChange', getFormatDate(v, 'yyyy-MM'))

    }
  },
}
</script>
<style lang="less">
.switch-button {
      height: 48px;
      border: 1px solid #42AE77;
      border-radius: 8px;
      .switch-item {
        height: 100%;
        padding: 0 18px;
        &.active {
          background: #42AE77;
          color: #fff;
        }
      }

      .arrow {
        transform-origin: center;
        transform: rotateZ(90deg);
        margin: 0 0 0 6px;
      }
    }
</style>
