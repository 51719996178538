<template>
  <div class="container">
    <nav-bar header-title="战队详情" :show-back="true" backColor="#fff" :header-back="appBack" />
    <div class="banner" :style="`background-image: url(${teamDetail.teamLogo})`">
      <div class="mask"></div>
    </div>

    <div class="team-box">
      <div class="avator">
        <img :src="teamDetail.teamLogo" alt="">
      </div>
      <div class="coach f22 row-center-center">
        教练：{{ teamDetail.teacherName }}
      </div>
      <div class="month-use f22 row-center-center">
        <p>本月消耗 <span class="fw6 f36">{{ teamDetail.calorieCurrentMonth }}</span> kcal</p>
      </div>
      <p class="name f38 fw6">{{ teamDetail.teamName }}</p>
      <p class="desc f24">{{ teamDetail.teamProfile }}</p>
      <div class="row-between-center">
        <div class="flex">
          <div class="info-item col-start-center">
            <p class="f30 fw6">{{ teamDetail.teamMembers }}</p>
            <p class="p2 f20">成员(人)</p>
          </div>
          <div class="info-item col-start-center">
            <p class="f30 fw6">{{ teamDetail.calorieHistory }}</p>
            <p class="p2 f20">历史消耗(kcal)</p>
          </div>
        </div>
        <div @click="goCourse" class="look-button f22 row-center-center">
          <img src="https://img.chaolu.com.cn/ACT/mini-team/schedule%402x.png" alt="">
          查看教练排课
        </div>
      </div>
      <div class="rank-list row-around-center">
        <div class="p2 f24 fw6">战队<br>排行</div>
        <div class="p1 f22 col-start-center" v-for="item,index in teamDetail.rankLabelList" :key="index">
          <p class=""><span :class="[item[1].indexOf('未参与') > -1 ? 'f22' : 'f28 fw6']">{{ item[1] }}</span>{{ item[1].indexOf('未参与') > -1  ? '' : '名' }}</p>
          <p class="">{{ item[0] }}月</p>
        </div>
      </div>
    </div>

    <div class="tab-box flex">
      <div @click="tabClick(index)" :class="['tab-item', 'row-center-center', tabActive === index ? 'active fw6' : '']" v-for="item,index in tabs" :key="index">{{ item }}</div>
    </div>

    <div v-show="tabActive === 0" class="data-box">
      <div class="data-title row-between-center">
        <p class="f32 fw6 row-start-center">战队达人</p>
        <date-switch @dateChange="date1Change"></date-switch>
      </div>
      <div class="data-con">
        <div class="title row-between-center">
          <div class="row-start-center">
            <img class="mod-icon" src="https://img.chaolu.com.cn/ACT/mini-team/square%402x.png" alt="">
            <p class="tt f26 fw6">打卡达人</p>
          </div>
          <p class="f22">单位 (节)</p>
        </div>
        <template v-if="nbListMap.clockTop.length">
          <div class="top3 row-start-center">
            <div @click="goUser(item.userId, item.secretStatus)" v-for="item in nbListMap.clockTop.slice(0, 3)" :key="item.userId" class="uesr-item big">
              <div class="s-border row-center-center">
                <img :src="item.headImage" alt="" class="avator">
              </div>
              <div class="num f22 fw6 row-center-center">{{ item.rankValue }}</div>
              <p class="name ellipsis f20">{{ item.userNickName }}</p>
            </div>
          </div>
          <div class="top8 flex flex-wrap">
            <div @click="goUser(item.userId, item.secretStatus)" v-for="item in nbListMap.clockTop.slice(3, 10)" :key="item.userId" class="uesr-item col-start-center">
              <img :src="item.headImage" alt="" class="avator">
              <div class="num f22 fw6 row-center-center">{{ item.rankValue }}</div>
              <p class="name f20 ellipsis">{{ item.userNickName }}</p>
            </div>
            <div v-if="nbListMap.clockTop.length >= 10" class="uesr-item col-start-center">
              <div class="avator more row-center-center">···</div>
              <div class="num f22 fw6 row-center-center">-</div>
              <p class="name f20">仅展示前10名</p>
            </div>
          </div>
        </template>
        <div v-else class="empty col-center-center">
          <img src="https://img.chaolu.com.cn/ACT/double11-2022/warm-up/empty.png" alt="">
          <p class="f28">暂无达人数据</p>
        </div>
      </div>

      <div class="data-con">
        <div class="title row-between-center">
          <div class="row-start-center">
            <img class="mod-icon" src="https://img.chaolu.com.cn/ACT/mini-team/square%402x.png" alt="">
            <p class="tt f26 fw6">卡路里达人</p>
          </div>
          <p class="f22">单位 (kcal)</p>
        </div>
        <template v-if="nbListMap.calorieTop.length">
          <div class="top3 row-start-center">
            <div @click="goUser(item.userId, item.secretStatus)" v-for="item in nbListMap.calorieTop.slice(0, 3)" :key="item.userId" class="uesr-item big">
              <div class="s-border row-center-center">
                <img :src="item.headImage" alt="" class="avator">
              </div>
              <div class="num f22 fw6 row-center-center">{{ item.rankValue }}</div>
              <p class="name ellipsis f20">{{ item.userNickName }}</p>
            </div>
          </div>
          <div class="top8 flex flex-wrap">
            <div @click="goUser(item.userId, item.secretStatus)" v-for="item in nbListMap.calorieTop.slice(3, 10)" :key="item.userId" class="uesr-item col-start-center">
              <img :src="item.headImage" alt="" class="avator">
              <div class="num f22 fw6 row-center-center">{{ item.rankValue }}</div>
              <p class="name f20 ellipsis">{{ item.userNickName }}</p>
            </div>
            <div v-if="nbListMap.calorieTop.length >= 10" class="uesr-item col-start-center">
              <div class="avator more row-center-center">···</div>
              <div class="num f22 fw6 row-center-center">-</div>
              <p class="name f20">仅展示前10名</p>
            </div>
          </div>
        </template>
        <div v-else class="empty col-center-center">
          <img src="https://img.chaolu.com.cn/ACT/double11-2022/warm-up/empty.png" alt="">
          <p class="f28">暂无达人数据</p>
        </div>
      </div>

      <div class="data-con">
        <div class="title row-between-center">
          <div class="row-start-center">
            <img class="mod-icon" src="https://img.chaolu.com.cn/ACT/mini-team/square%402x.png" alt="">
            <p class="tt f26 fw6">拉新达人</p>
          </div>
          <p class="f22">单位 (人)</p>
        </div>

        <div class="top10 flex flex-wrap">
          <template v-if="nbListMap.newUserInviteTop.length">
            <div @click="goUser(item.userId, item.secretStatus)" v-for="item in nbListMap.newUserInviteTop" :key="item.userId" class="uesr-item col-start-center">
              <img :src="item.headImage" alt="" class="avator">
              <div class="num f22 fw6 row-center-center">{{ item.rankValue }}</div>
              <p class="name f20 ellipsis">{{ item.userNickName }}</p>
            </div>
          </template>
          <div v-else class="empty low col-center-center">
            <!-- <img src="https://img.chaolu.com.cn/ACT/double11-2022/warm-up/empty.png" alt=""> -->
            <p class="f28">暂无达人数据</p>
          </div>
        </div>
        <div class="rule-bar f22">新人首节小班课在本战队即可完成拉新，且首节可获得双倍卡路里。</div>
      </div>
    </div>

    <div v-show="tabActive === 0" class="data-box pb56">
      <div class="data-title row-between-center">
        <p class="f32 fw6">食物消灭情况</p>
        <date-switch @dateChange="date2Change"></date-switch>
      </div>
      <div class="fruit-con flex flex-wrap">
        <template v-if="foodConsume.length">
          <div class="fruit-item col-center-center" v-for="item in foodConsume" :key="item.foodName">
            <img :src="item.img" alt="" class="fruit">
            <p class="num f28 fw6">x{{ item.foodNum }}</p>
          </div>
        </template>
        <div v-else class="empty col-center-center" style="border: 1px solid #eee;">
          <img src="https://img.chaolu.com.cn/ACT/double11-2022/warm-up/empty.png" alt="">
          <p class="f28">暂无食物消灭数据</p>
        </div>
      </div>
    </div>

    <div v-show="tabActive === 1" class="data-box pb56">
      <p class="f24 tip">* 完成当前战队教练的燃脂塑型小班课即可加入战队</p>
      <div class="data-title row-between-center">
        <p class="f32 fw6">您的队内排名</p>
        <date-switch @dateChange="date3Change"></date-switch>
      </div>
      <div class="contribute-con">
        <div v-if="contribution.finishedLesson" class="contribute-item row-between-center f22">
          <div>
            <p class="p1">总打卡课程</p>
            <p><span class="f36 fw6">{{ contribution.finishedLesson }}</span>节</p>
          </div>
          <div>
            <p class="p1">队内排名</p>
            <p><span class="f36 fw6">{{ contribution.finishedLessonRank }}</span>名</p>
          </div>
          <img src="https://img.chaolu.com.cn/ACT/mini-team/contribute2.png" alt="">
        </div>
        <div v-else class="empty col-center-center">
          <img src="https://img.chaolu.com.cn/ACT/double11-2022/warm-up/empty.png" alt="">
          <p class="f28">暂无数据</p>
        </div>
        <div v-if="contribution.trainingTimeTotal" class="contribute-item row-between-center f22">
          <div>
            <p class="p1">总课程时长</p>
            <p><span class="f36 fw6">{{ contribution.trainingTimeTotal }}</span>min</p>
          </div>
          <div>
            <p class="p1">队内排名</p>
            <p><span class="f36 fw6">{{ contribution.trainingTimeRank }}</span>名</p>
          </div>
          <img src="https://img.chaolu.com.cn/ACT/mini-team/contribute4.png" alt="">
        </div>
        <div v-if="contribution.calorieTotal" class="contribute-item row-between-center f22">
          <div>
            <p class="p1">总消耗热量</p>
            <p><span class="f36 fw6">{{ contribution.calorieTotal }}</span>kcal</p>
          </div>
          <div>
            <p class="p1">队内排名</p>
            <p><span class="f36 fw6">{{ contribution.calorieRank }}</span>名</p>
          </div>
          <img src="https://img.chaolu.com.cn/ACT/mini-team/contribute1.png" alt="">
        </div>
        <div v-if="contribution.fatTotal" class="contribute-item row-between-center f22">
          <div>
            <p class="p1">总消耗脂肪</p>
            <p><span class="f36 fw6">{{ contribution.fatTotal }}</span>g</p>
          </div>
          <div>
            <p class="p1">队内排名</p>
            <p><span class="f36 fw6">{{ contribution.fatRank }}</span>名</p>
          </div>
          <img src="https://img.chaolu.com.cn/ACT/mini-team/contribute3.png" alt="">
        </div>

      </div>

    </div>

    <van-popup v-model="tipShow" position="center" class="popsure">
      <div class="sure-title">达人排行说明</div>
      <div class="sure-content">看下就看电视剧非</div>
      <div class="sure-sub" @click="tipShow = false">我知道了</div>
    </van-popup>
  </div>
</template>

<script>
import wx from "weixin-js-sdk"
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack, gotoCoachDetail, goUserPage } from '@/lib/appMethod'
import navBar from '@/components/nav-bar/nav-bar'
import { getParam, getFormatDate } from '@/lib/utils'
import dateSwitch from './components/date-switch'

export default {
  mixins: [userMixin],
  components: {
    navBar,
    dateSwitch
  },
  data() {
    return {
      tabs: ['战队数据', '您的队内排名'],
      tabActive: 0,

      params: {
        queryMonth: getFormatDate(new Date(), 'yyyy-MM'),
        teacherId: '',
        teamId: 0,
        userId: 0,
      },
      teamDetail: {},
      nbListMap: {
        clockTop: [],
        calorieTop: [],
        newUserInviteTop: []
      }, // 达人榜
      foodConsume: [],
      contribution: {},

      tipShow: false,
    }
  },
  computed: {},
  async created() {
    await this.$getAllInfo()
    initBack()

    this.params.userId = this.userId
    this.params.teamId = getParam().teamId

    this.getTeamDetail()
    this.getNbList()
    this.getFoodConsume()
    this.getContribution()
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    appBack: newAppBack,
    getTeamDetail() {
      this.$axios.post(`${this.baseURLApp}/microTeacherTeamUser/detail`, this.params).then(res => {
        this.teamDetail = res.data

        if (this.teamDetail.rankLabelList.length) {
          this.teamDetail.rankLabelList.map((item, index) => {
            item = item.split('月')
            this.$set(this.teamDetail.rankLabelList, index, item)
          })
        }
        console.log(this.teamDetail.rankLabelList);
      })
    },
    getNbList() {
      this.$axios.post(`${this.baseURLApp}/microTeacherTeamUser/userTop`, this.params).then(res => {
        this.nbListMap = res.data
      })
    },
    getFoodConsume() {
      this.$axios.post(`${this.baseURLApp}/microTeacherTeamUser/foodConsume`, this.params).then(res => {
        this.foodConsume = res.data
      })
    },
    getContribution() {
      this.$axios.post(`${this.baseURLApp}/microTeacherTeamUser/contribution`, this.params).then(res => {
        this.contribution = res.data
      })
    },

    // 查看排课
    async goCourse() {

      if (this.appTypeStr === 'mini') {
        wx.miniProgram.navigateTo({
          url: `/pages/priPages/coachDetail/coachDetail?teacherId=${this.teamDetail.teacherId}&autoOpenMini=1`
        })
      } else {
        gotoCoachDetail(this.teamDetail.teacherId)
      }
    },

    date1Change(v) {
      this.params.queryMonth = v
      this.getNbList()
    },
    date2Change(v) {
      this.params.queryMonth = v
      this.getFoodConsume()
    },
    date3Change(v) {
      this.params.queryMonth = v
      this.getContribution()
    },
    tabClick(i) {
      this.tabActive = i
    },
    goUser(id, status) {
      if (status == 1) {
        this.$toast('已设置保密，无法查看')
      } else {
        goUserPage(id, 2)
      }
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  min-height: 100vh;
  * {
    box-sizing: border-box;
  }
  background-color: #F5F5F5;
  padding: 0 0 constant(safe-area-inset-bottom);
  padding: 0 0 env(safe-area-inset-bottom);
  .banner {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 380px;
    position: relative;
    .mask {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .3);
    }
  }
  .team-box {
    position: relative;
    border-radius: 30px 30px 0px 0px;
    padding: 112px 32px 20px;
    margin: -32px 0 0;
    background-color: #fff;
    .avator {
      width: 136px;
      height: 136px;
      background-image: url(https://img.chaolu.com.cn/ACT/mini-team/border_middle%402x.png);
      background-size: 100% 100%;
      position: absolute;
      left: 33px;
      top: -62px;
      padding: 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-color: #fff;
      }
    }
    .coach {
      padding: 0 14px;
      height: 46px;
      position: absolute;
      left: 186px;
      top: 24px;
      background: #EFF7F3;
      border: 1px solid #C9DECF;
      border-radius: 6px;
    }
    .month-use {
      width: 374px;
      height: 80px;
      background-image: url(https://img.chaolu.com.cn/ACT/mini-team/bg_calories%402x.png);
      background-size: 100% 100%;
      padding: 0 0 0 24px;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 0 30px 0 0;
    }
    .desc {
      margin: 22px 0 46px;
    }
    .info-item {
      margin: 0 72px 0 0;
      .p2 {
        color: #6C727A;
      }
    }
    .look-button {
      padding: 0 20px;
      height: 54px;
      background: #42AE77;
      border-radius: 8px;
      color: #fff;
      img {
        width: 26px;
        margin: 0 8px 0 0;
      }
    }
    .rank-list {
      width: 686px;
      height: 134px;
      background: linear-gradient(90deg, #F4FAF7 0%, #ECF5F0 100%);
      border-radius: 16px;
      margin: 50px 0 20px;
      .p1 {
        line-height: 33px;
      }
      .p2 {
        color: #42AE77;
      }
    }
  }
  .tab-box {
    width: 100%;
    height: 80px;
    background-color: #fff;
    .tab-item {
      width: 50%;
      height: 100%;
      &.active {
        color: #42AE77;
        position: relative;
      }
      &.active::after {
        content: '';
        width: 100%;
        height: 4px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #42AE77;
      }
    }
  }
  .data-box {
    background-color: #F5F5F5;
    padding: 48px 32px 0;
    .tip {
      color: #6C727A;
      margin: 0 0 48px;
    }
    .q-icon {
      width: 26px;
      margin: 0 0 0 8px;
    }

    .data-con {
      background-color: #fff;
      padding: 40px 0;
      margin: 16px 0 0;
      border-radius: 12px;
      border: 1px solid #EEEEEE;
      .title {
        padding: 0 30px;
        .mod-icon {
          width: 13px;
        }
        .tt {
          margin: 0 8px;
        }
      }
      .top3 {
        margin: 24px 0 0;
        padding: 0 12px;
      }
      .top8 {
        width: 100%;
        .uesr-item {
          margin: 32px 0 0 0;
          width: 25%;
        }
      }
      .top10 {
        width: 100%;
        .uesr-item {
          margin: 32px 0 0 0;
          width: 20%;
        }
      }
      .uesr-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        .s-border {
          width: 142px;
          height: 142px;
          background: #FFFFFF;
          border: 4px solid #42AE77;
          border-radius: 50%;
        }
        .avator {
          width: 76px;
          height: 76px;
          border-radius: 50%;
          &.more {
            background-color: #F0F7F3;
            color: #42AE77;
          }
        }
        .num {
          padding: 0 14px;
          height: 28px;
          background: #42AE77;
          border: 2px solid #FFFFFF;
          border-radius: 16px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 68px;
          color: #fff;
        }
        .name {
          width: 100%;
          margin: 28px 0 0;
          text-align: center;
        }
        &.big {
          width: 33.3%;
          .avator {
            width: 130px;
            height: 130px;
          }
          .name {
            width: 150px;
            margin: 28px 0 0;
          }
          .num {
            top: 128px;
          }
        }
      }
      .rule-bar {
        width: 626px;
        padding: 12px 32px;
        background: #E4F5EC;
        border-radius: 16px;
        margin: 28px auto 0;
      }
    }
    .fruit-con {
      margin: 22px 0 0;
      .fruit-item {
        width: 164px;
        padding: 0 0 8px;
        background: #FFFFFF;
        border-radius: 12px;
        margin: 0 0 10px 0;
        border: 1px solid #EEEEEE;
        &:not(:nth-child(4n)) {
          margin: 0 10px 10px 0;
        }
        .fruit {
          width: 164px;
          height: 164px;
          object-fit: contain;
        }
        .num {
          color: #42AE77;
          margin: 4px 0 0;
        }
      }
    }

    .contribute-con {
      margin: 22px 0 0;
      .contribute-item {
        width: 100%;
        height: 144px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        padding: 0 0 0 60px;
        margin: 0 0 16px;
        border-radius: 12px;
        .p1 {
          margin: 0 0 8px;
        }
        span {
          margin: 0 4px 0 0;
        }
        img {
          width: 222px;
        }
      }
    }
  }
  .empty {
    width: 100%;
    padding: 100px 0 80px;
    background-color: #fff;
    border-radius: 12px;
    &.low {
      padding: 70px 0 50px;
    }
    img {
      width: 320px;
    }
    p {
      color: #6C727A;
      margin: 16px 0 0;
    }
  }
  .popsure {
    width: 590px;
    border-radius: 12px;
    padding: 50px 40px;
    .sure-title {
      color: #242831;
      font-size: 36px;
      font-weight: bold;
      text-align: center;
    }
    .sure-content {
      color: #3C454E;
      font-size: 24px;
      line-height: 1.6;
      margin: 36px 0 48px;
      text-align: center;
    }
    .sure-sub {
      border-radius: 8px;
      background: #FFDE00;
      color: #000;
      font-weight: bold;
      font-size: 32px;
      line-height: 96px;
      text-align: center;
    }
  }
  .pb56 {
    padding-bottom: 56px;
  }
}
</style>
